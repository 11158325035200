exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-crew-js": () => import("./../../../src/pages/crew.js" /* webpackChunkName: "component---src-pages-crew-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-research-js": () => import("./../../../src/pages/research.js" /* webpackChunkName: "component---src-pages-research-js" */),
  "component---src-pages-sound-js": () => import("./../../../src/pages/sound.js" /* webpackChunkName: "component---src-pages-sound-js" */),
  "component---src-pages-textual-js": () => import("./../../../src/pages/textual.js" /* webpackChunkName: "component---src-pages-textual-js" */),
  "component---src-pages-tv-js": () => import("./../../../src/pages/tv.js" /* webpackChunkName: "component---src-pages-tv-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-vision-js": () => import("./../../../src/pages/vision.js" /* webpackChunkName: "component---src-pages-vision-js" */)
}

